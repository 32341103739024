export default {

	// global configuration for all plugins
	global: {
		// all per ajax loaded content will be passed to this element
		contentArea: '#col3_content',
		// if no #col3_content is found in the ajax response, this one will
		// be selected instead
		alternateContentContainer: '.struts-content-area',
		hoverImageSuffix: '_mouseover',
		fullPageOnFirstClick: false,
		panelSelector: ' div.panel, .reportItem',
		ckeditorSelector: '.inlineckeditor:not(.inlineckeditoroutput)',
	},

	ScrollHelper: {
		contentSelector: '#col3_content',
	},

	SectionScrollHelper: {
		contentSelector: '#col3_content',
		sectionSelector: '[data-toggle-group]',
		minSectionCount: 5,
		position: 'right',
		wofContainerOffset: {
			x: 5,
		},
		arrowOffset: {
			x: 0,
		},
		possibleSections: {
			// entspricht der CSS-Klasse einer Section
			'panel': {
				header: '> .panelHeader',
				content: '> .panelContent',
			},
		},
	},

	OverlayHelper: {
		overlayClassName: 'overlay ajax',
		overlayImageClassName: 'ajaxOverlayImage',
		overlayImageSource: 'page/default/style/img/edit-loader.gif',
	},

	// plugin specific configurations

	/**
	 * ContentHandler-Config Possible options exclude : an array of CSS-Selectors that should be prevented binding an content
	 *  ajax event ignore : an array of CSS-Selectors that should be prevented
	 * from causing a content ajax request eventType : type of user-interaction (click,mouseover etc)
	 */
	ContentHandler: {
		// WICHTIG: Stell die richtige Frage, alles hängt vom a-tag ab
		exclude: [
			'a.formSubmit', 'input[type="submit"]', 'a[target="_blank"]', '.closeDialog', '.openDialog', 'input[type="button"]',
			'.tabs-list a', 'a.tabControl', '.exclude',
			'.cke_dialog_ui_button', '.cke_dialog_close_button', '.cke_toolbox_collapser', '#bannerOverview',
		],
		// diese Matches führen IMMER den Link aus.
		ignore: ['#login', '.ajaxIgnore', 'a[target]', 'div[target]'],
		reinitStartup: new Array('.reinitStartup'),
		uiComponent: new Array('.openDialog'),
	},

	/**
	 * FormHandler-Config Possible options eventType : type of user interaction (click,mouseover etc) actionCodeSelector
	 * : CSS-Selector of the form-field containing the actionCode
	 * eventListenerSelector : CSS-Selector of the DOM-Element that should get event listeners
	 */
	FormHandler: {
		eventType: 'click',
		actionCodeSelector: 'input[name="actionCode"]',
		eventListenerSelectors: 'input[type="submit"],a.formSubmit:not(.editPage),input[type="image"]',
	},

	/**
	 * AjaxRotator-Config Possible options eventContext : Context the ajaxStart and ajaxStop-Events will be appended to appendArea :
	 *  CSS-Selector of area ajaxOverlay will be appended to
	 * overlayClassName : CSS-Classname of the overlay div-tag overlayImageClassName : CSS-Classname of the substituted div around
	 *  the ajax-loader image overlayImageSource : the path to the overlay
	 * image
	 */
	AjaxRotator: {
		// useErrorTooltips: Will be set in dependencyManager
		eventContext: document,
		appendArea: 'body',
		stinkyElementList: [
			'#jstree-marker', '#jstree-marker-line', '#vakata-contextmenu', '#ui-datepicker-div',
			'div.popModal[data-popmodal_id]', 'div.popover.clockpicker-popover',
		],
		progressMonitor: {
			rotatorSelector: 'div.ajaxOverlayImage',
			percentageConf: {
				show: true,
				delimiter: '%',
				showTotal: false,
			},
			animationConf: {
				show: true,
				mode: 'default',
			},
		},
		defaultOffsetTop: 0,
	},

	EditViewStatic: {
		features: {
			openDialog: false,
			reload: false,
			autoScroll: true,
			connectInputFields: false,
			zoom: true,
			rectangles: false,
			dynamicZoom: true,
			drag: true,
			superView: true,
			pdfDownload: false,
		},
	},
	EditViewSome: {
		features: {
			openDialog: true,
			reload: true,
			autoScroll: true,
			connectInputFields: false,
			zoom: false,
			rectangles: false,
			dynamicZoom: false,
			drag: true,
			superView: false,
			pdfDownload: false,
		},
	},

	AreaTreeBehaviour: {
		tillTreeDepth: 1,
	},

	/**
	 * CKEditor-Config
	 */
	CKEditorHandler: {
		fontSizes: {},
		toolbars: {
			toolbar_HtmlArea: [
				[
					'Bold', '-', 'Link', 'Unlink',
				],
			],
			toolbar_HtmlArea1: [
				[
					'Bold', 'Italic', 'Underline',
				], [
					'Link', 'Unlink',
				],
			],
			toolbar_HtmlArea2: [
				[
					'Cut', 'Copy', 'PasteText', '-', 'Undo', 'Redo',
				], [
					'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
				], [
					'Link', 'Unlink', 'Anchor',
				], [
					'FontSize', 'SpecialChar', 'TextColor', 'BGColor',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter',
					'JustifyRight', 'JustifyBlock', '-',
				],
			],
			toolbar_HtmlArea1YT: [
				[
					'Bold', 'Italic', 'Underline',
				], [
					'Link', 'Unlink', 'Image',
				],
			],
			toolbar_HtmlAreaNews: [
				[
					'Bold', 'Italic',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'FontSize', 'SpecialChar',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'Link', 'Unlink',
				],
			],
			toolbar_history: [
				[
					'FontSize',
				], [
					'Bold', 'Italic', 'Underline',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'SpecialChar',
				],
			],
			toolbar_impressum: [
				[
					'Bold', '-', 'Link', 'Unlink', '-', 'HorizontalRule', 'Format',
				],
			],
			toolbar_mp_description: [
				[
					'Bold', 'Italic', 'Underline',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'Link', 'Unlink',
				], [
					'Subscript', 'Superscript',
				],
			],
			toolbar_einpflege_feedback: [
				[
					'Undo', 'Redo',
				], [
					'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'JustifyLeft', 'JustifyCenter',
					'JustifyRight', 'JustifyBlock',
				], [
					'base64image',
				],
			],
		},
		config: {
			customConfig: context + '/ckeditor/config.js',
			enterMode: 2, // lines are broken with <br> elements; http://docs.cksource.com/ckeditor_api/symbols/CKEDITOR.config.html#.enterMode,
			// alte typograhie.less mit p margin 0 0 0 0 beibehalten für bestehende "p"-tags im ckeditor
			/*
			 * contentsCss: [ context + '/page/style/css/organism/style.css', context + '/page/style/css/modules/general/text.css', context + '/page/style/css/modules/general/ckeditor.css' ],
			 */
			entities_latin: false,
			entities: false,
			plugins: 'about,basicstyles,blockquote,clipboard,colorbutton,contextmenu,dialog,dialogui,enterkey,entities,fakeobjects,filebrowser,floatingspace,font,format,horizontalrule,html5video,htmlwriter,image2,indent,indentblock,indentlist,justify,lineutils,link,list,maximize,menu,menubutton,oembed,panel,panelbutton,pastefromword,pastetext,popup,preventEnter,removeformat,resize,richcombo,scayt,showborders,sourcearea,specialchar,stylescombo,tab,table,undo,widget,wysiwygarea',
			forcePasteAsPlainText: true,
			fontSize_sizes: '8pt;10pt;11pt;12pt;13pt;14pt;16pt;18pt;20pt;23pt;24pt',
			skin: 'moono',
			resize_enabled: false,
			// nicht setzen, damit http://docs.ckeditor.com/#!/guide/dev_advanced_content_filter verwendet wird
			// mit allowedContent: true können auch nicht erlaubte Tags kopiert werden
			// allowedContent: true,
			scayt_autoStartup: false,
			scayt_sLang: 'de_DE',
			width: 448,
			keystrokes: [
				[
					1114112 + 76 /* L */, null,
				], [
					1114112 + 66 /* B */, null,
				], [
					1114112 + 73 /* I */, null,
				], [
					1114112 + 85 /* U */, null,
				],
			],
			linkShowAdvancedTab: false,
			linkTargets_webapps: [
				['notSet', '_self', '_blank'],
			],
		},
		elements: {
			a: function (element) {
				element.attributes['class'] = 'defaultLink ajaxIgnore';
				return element;
			},
		},
	},
	Lightbox: {},
	BildDBLightBox: {
		linkBoxLabelIsClickable: false,
		linkBoxHasControls: false,
		isBildDB: true,
		lightboxResultSetSelector: 'div.imageList',
		lightboxTriggerSelector: 'a#pictureDbPreviewButton',
	},
	LightboxTabulation: {
		lightboxResultSetSelector: '.imageList:first',
		overlayContainerSelector: '.overlayContainer:first',
		imageDisplayDelay: 1000,
	},
	AutoComplete: {},
	TabIndexer: {
		indexArea: '#col3_content',
		removeLinkTabs: false,
	},
	LocaleHelper: {
		mappings: {
			en: [
				'en', 'en_GB',
			],
			de: [
				'de', 'de_DE',
			],
			nl: [
				'nl', 'nl_NL',
			],
		},
		fallbackLang: 'de_DE',
	},
	MakeDatepicker: {
		regionals: {
			en: {
				closeText: 'Done',
				prevText: 'Prev',
				nextText: 'Next',
				currentText: 'Today',
				monthNames: [
					'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November',
					'December',
				],
				monthNamesShort: [
					'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
				],
				dayNames: [
					'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
				],
				dayNamesShort: [
					'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
				],
				dayNamesMin: [
					'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
				],
				weekHeader: 'Wk',
				dateFormat: 'dd/mm/yy',
				firstDay: 0,
				isRTL: false,
				showMonthAfterYear: false,
				buttonText: 'Calendar',
				yearSuffix: '',
			},
			de: {
				closeText: 'schlie&szlig;en',
				prevText: 'zurück',
				nextText: 'vor',
				currentText: 'Heute',
				monthNames: [
					'Januar', 'Februar', 'M&auml;rz', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November',
					'Dezember',
				],
				monthNamesShort: [
					'Jan', 'Feb', 'M&auml;r', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez',
				],
				dayNames: [
					'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag',
				],
				dayNamesShort: [
					'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
				],
				dayNamesMin: [
					'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
				],
				weekHeader: 'KW',
				dateFormat: 'dd.mm.yy',
				firstDay: 1,
				isRTL: false,
				showMonthAfterYear: false,
				buttonText: 'Kalender',
				yearSuffix: '',
			},
			nl: {
				closeText: 'Sluiten',
				prevText: '←',
				nextText: '→',
				currentText: 'Vandaag',
				monthNames: [
					'januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november',
					'december',
				],
				monthNamesShort: [
					'jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec',
				],
				dayNames: [
					'zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag',
				],
				dayNamesShort: [
					'zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat',
				],
				dayNamesMin: [
					'zo', 'ma', 'di', 'wo', 'do', 'vr', 'za',
				],
				weekHeader: 'Wk',
				dateFormat: 'd-m-yy',
				firstDay: 1,
				isRTL: false,
				showMonthAfterYear: false,
				buttonText: 'kalender',
				yearSuffix: '',
			},
		},
		/*
		 * weitere sprach-configs siehe: https://github.com/jquery/jquery-ui/tree/master/ui/i18n Verwendung siehe: http://docs.jquery.com/UI/Datepicker
		 */

	},
	SectionLoader: {
		selectorList: [
			'#meta',
		],
	},

	AccessibleTabs: {
		accessibleTabsOptions: {
			fx: 'show',
			fxspeed: null,
			wrapperClass: 'tabContent',
			cssClassAvailable: true,
			heightStyle: 'fill',
		},
		tabProcessControlSelector: 'a.tabControl',
	},

	LightboxPreview: {
		lightboxPreviewOptions: {
			multidevicepreview: true,
		},
	},
	SelectPreviewSwitch: {
		timeoutDuration: 300,
	},
	ReportListPanel: {
		iconIsToggleTrigger: true,
		initialState: 'closed',
		selectors: {
			panelLabel: '.reportName',
			panelHeader: '.toggleTrigger ',
			panelContent: '.toggleContent',
			panelToggleIcon: '.toggleTrigger .icon.defaultLink',
		},
	},
	GroupButtonPanel: {
		iconIsToggleTrigger: true,
		initialState: 'closed',
		useLocalStorage: false,
		selectors: {
			panelLabel: '> header:first-child',
			panelHeader: '> header:first-child',
			panelContent: '> *:not(header)',
			panelToggleIcon: '.groupButtons .toggleTrigger',
		},
		cssClass: {
			iconExpand: 'moveUp_GroupSize',
			iconClosed: 'moveDown_GroupSize',
		},
	},
	ClockPicker: {
		autoclose: true,
		hideMinutes: false,
		disabledHours: [
			23, 0,
		],
		minutesModulo: 15,
	},
	SmartFilter: {
		useLocalStorage: false,
	},
	SmarterTooltip: {},
};
